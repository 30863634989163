<script setup lang="ts">
defineEmits(['onFileInputChange', 'changeImageInput']);
const props = defineProps<{ projectImages: Record<string, any>[] }>();
const { projectImages } = props;

function isEven(number: number) {
    return number % 2 === 0;
}
</script>
<template>
    <div id="change-image-modal" tabindex="-1" class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div class="relative w-full max-w-7xl max-h-full">
            <!-- Modal content -->
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <!-- Modal header -->
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                    <h3 class="text-xl font-medium text-gray-900 dark:text-white">เลือกเปลี่ยนภาพแสดง</h3>
                    <button
                        type="button"
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="change-image-modal"
                        @click="closeModal('change-image-modal')"
                    >
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <div class="p-4 md:p-5 space-y-4 h-[32rem] overflow-y-auto">
                    <div class="flex justify-center items-center gap-10">
                        <div class="grid w-1/3">
                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">อัพโหลดไฟล์</label>
                            <input
                                id="file_input"
                                class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                type="file"
                                @input="$emit('onFileInputChange', $event)"
                            >
                        </div>
                        <span class="mt-5 dark:text-white">หรือ</span>
                        <div class="grid w-1/3">
                            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ลิงค์รูปภาพ</label>
                            <input
                                type="url"
                                class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                placeholder="ช่องกรอก URL รูปภาพ"
                                @input="$emit('changeImageInput', $event)"
                            >
                        </div>
                    </div>
                    <div class="inline-flex items-center justify-center w-full text-center">
                        <hr class="w-1/3 h-px my-8 ms-[10rem] bg-gray-200 border-0 dark:bg-gray-700" >
                        <span class="w-1/3 font-medium text-gray-900 bg-white dark:text-white dark:bg-gray-900">หรือเลือกภาพของโปรเจกต์นี้จากระบบ</span>
                        <hr class="w-1/3 h-px my-8 me-[10rem] bg-gray-200 border-0 dark:bg-gray-700" >
                    </div>

                    <div class="grid grid-cols-2 place-items-center place-content-center gap-10 pb-10">
                        <img
                            v-for="(img, imgIndex) in projectImages"
                            :key="'project_image_' + img.url"
                            :src="img.url"
                            :alt="img.name"
                            :class="[isEven(imgIndex) ? 'place-self-end' : 'place-self-start', 'w-1/2 border-[1px] p-2 rounded-lg hover:bg-gray-100 hover:shadow-md cursor-pointer']"
                            @click="$emit('changeImageInput', img.url)"
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
